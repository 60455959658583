import { getDashboardFilesAndFolders, createFolder, deleteFolderOrFile, moveFolderOrFile, renameFolderOrFile, getFirstFilesAndFolders, } from '@/api/file_folder';
import { Message } from '@arco-design/web-vue';
const fileModule = {
    namespaced: true,
    state: () => ({
        dashboard: {
            files: [],
            totalFiles: 0,
        },
        fileManager: {
            files: [],
            totalFiles: 0,
            currentPage: 1,
            pageSize: 10,
            currentFolderCode: '',
            currentFolderName: '',
        },
        fileDetail: {
            file: {
                id: undefined,
                code: '',
                parentCode: '',
                type: '',
                userUid: '',
                name: '',
                fileUrl: '',
                status: '',
                translatedFileCode: '',
                createTime: undefined,
                updateTime: undefined,
                expiredTime: undefined,
                duration: undefined,
                previewUrl: '',
            },
        },
    }),
    mutations: {
        SET_DASHBOARD_FILES(state, files) {
            state.dashboard.files = files;
        },
        SET_DASHBOARD_TOTAL(state, total) {
            state.dashboard.totalFiles = total;
        },
        SET_FILE_MANAGER_FILES(state, files) {
            state.fileManager.files = files;
            console.log('State after mutation:', state.fileManager.files);
        },
        SET_FILE_MANAGER_TOTAL(state, total) {
            state.fileManager.totalFiles = total;
        },
        SET_FILE_MANAGER_PAGE(state, page) {
            state.fileManager.currentPage = page;
        },
        SET_FILE_MANAGER_PAGE_SIZE(state, size) {
            state.fileManager.pageSize = size;
        },
        SET_FILE_DETAIL(state, file) {
            state.fileDetail.file = file;
        },
    },
    actions: {
        async fetchDashboardFiles({ commit }) {
            try {
                const response = await getDashboardFilesAndFolders();
                commit('SET_DASHBOARD_FILES', response.data);
            }
            catch (error) {
                console.error('Error fetching dashboard files:', error);
                Message.error('获取仪表盘文件列表失败，请稍后重试。');
            }
        },
        async fetchFileManagerFiles({ commit, state }, params) {
            try {
                console.log('Fetching file manager files with params:', params);
                const response = await getFirstFilesAndFolders(params);
                console.log('File manager files response:', response);
                commit('SET_FILE_MANAGER_FILES', response.data.dataList);
                commit('SET_FILE_MANAGER_TOTAL', response.data.pageParam?.totalElements);
                commit('SET_FILE_MANAGER_PAGE', params.pageNum);
                commit('SET_FILE_MANAGER_PAGE_SIZE', params.pageSize);
            }
            catch (error) {
                console.error('Error fetching file manager files:', error);
                Message.error('获取文件管理器列表失败，请稍后重试。');
                throw error;
            }
        },
        async changeFileManagerPage({ commit, dispatch, state }, page) {
            commit('SET_FILE_MANAGER_PAGE', page);
            try {
                const response = await getFirstFilesAndFolders({
                    pageNum: page,
                    pageSize: state.fileManager.pageSize,
                });
                commit('SET_FILE_MANAGER_FILES', response.data.dataList);
                commit('SET_FILE_MANAGER_TOTAL', response.data.pageParam?.totalElements);
            }
            catch (error) {
                console.error('Error fetching file manager files:', error);
                Message.error('获取文件列表失败，请稍后重试。');
            }
        },
        async changeFileManagerPageSize({ commit, dispatch }, size) {
            commit('SET_FILE_MANAGER_PAGE_SIZE', size);
            commit('SET_FILE_MANAGER_PAGE', 1);
            await dispatch('fetchFileManagerFiles');
        },
        async renameFile({ dispatch }, { type, fileId, newName }) {
            try {
                await renameFolderOrFile(type, fileId, newName);
                Message.success('文件重命名成功');
                await dispatch('fetchFileManagerFiles');
            }
            catch (error) {
                console.error('Error renaming file:', error);
                Message.error('文件重命名失败，请稍后重试。');
            }
        },
        async moveFile({ dispatch }, { type, fileId, newPath }) {
            try {
                await moveFolderOrFile(type, fileId, newPath);
                Message.success('文件移动成功');
                await dispatch('fetchFileManagerFiles');
            }
            catch (error) {
                console.error('Error moving file:', error);
                Message.error('文件移动失败，请稍后重试。');
            }
        },
        async deleteFile({ dispatch }, { type, fileId }) {
            try {
                await deleteFolderOrFile(type, fileId);
                Message.success('文件删除成功');
                await dispatch('fetchFileManagerFiles');
            }
            catch (error) {
                console.error('Error deleting file:', error);
                Message.error('文件删除失败，请稍后重试。');
            }
        },
        async createFolder({ dispatch }, { folderName, folderCode }) {
            try {
                await createFolder(folderName, folderCode);
                Message.success('文件夹创建成功');
                await dispatch('fetchFileManagerFiles', folderCode);
            }
            catch (error) {
                console.error('Error creating folder:', error);
                Message.error('文件夹创建失败，请稍后重试。');
            }
        },
    },
    getters: {
        dashboardFiles: (state) => state.dashboard.files,
        dashboardTotalFiles: (state) => state.dashboard.totalFiles,
        fileManagerFiles: (state) => state.fileManager.files,
        fileManagerTotalFiles: (state) => state.fileManager.totalFiles,
        fileManagerCurrentPage: (state) => state.fileManager.currentPage,
        fileManagerPageSize: (state) => state.fileManager.pageSize,
    },
};
export default fileModule;
