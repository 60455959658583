import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'LegalInformation',
    setup() {
        const legalInfo = ref({
            販売業者: 'トンダ株式会社',
            所在地: '〒 102-0082 東京都千代田区一番町２３番地２番町ロイヤルコート８０６',
            運営統括責任者: '代表取締役 LIN JUNNAN',
            電話番号: '050-8888-6067',
            電話受付時間: '受付時間 10:00-17:00（土日祝を除く）\n※受付時間外の場合は、メールにてお問い合わせください。',
            メールアドレス: 'support@xtranscript.com',
            'サイト URL': 'xtranscript.com',
            商品の販売価格: '各商品ページをご参照ください。',
            商品代金以外に必要な料金: [
                '配送料（宅急便：0 円、メール便：0 円）',
                '手数料（コンビニ決済：0 円、代引き: 0 円）',
            ],
            支払方法: 'Alipay・Wechat Pay・クレジットカード決済',
            支払時期: [
                'Alipay：商品注文時にお支払いが確定します。',
                'Wechat Pay：商品注文時にお支払いが確定します。',
                'クレジットカード決済：商品注文時にお支払いが確定します。',
            ],
            商品の引渡時期: 'ご注文いただいた後、10 分以内に発送いたします。',
            '返品・交換': '商品到着後 7 日以内に限り返品・交換が可能です。',
            返品送料: '商品に欠陥がある場合には当方負担、お客様のご都合による返品・交換の場合にはお客様負担となります。',
        });
        return {
            legalInfo,
        };
    },
});
