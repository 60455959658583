import axiosInstance from './axios';
import HttpApi from './endpoints';
/**
 * Sends a login request to the API.
 *
 * @param data - The login request payload.
 * @returns A promise that resolves to an ApiResponse containing LoginResponse data.
 */
export const login = (data) => {
    return axiosInstance.post(HttpApi.login, data);
};
/**
 * Sends a registration request to the API.
 *
 * @param data - The registration request payload.
 * @returns A promise that resolves to an ApiResponse containing LoginResponse data.
 */
export const register = (data) => {
    return axiosInstance.post(HttpApi.register, data);
};
/**
 * 获取用户信息
 *
 * @param deviceId - 设备ID
 * @returns Promise<ApiResponse<User>> - 包含用户信息的API响应
 */
export const getUserInfo = (deviceId) => {
    return axiosInstance.get(HttpApi.userInfo, {
        params: { deviceId },
    });
};
/**
 * Sends a request for a verification code to the specified email.
 *
 * @param email - The user's email address.
 * @param type - The type of verification code (e.g., 'register', 'changePwd', 'login', 'changeEmail', 'changeNoticeEmail').
 * @returns A promise that resolves to an ApiResponse.
 */
export const sendVerificationCode = (email, type) => {
    return axiosInstance.post(HttpApi.sendSms, { email, type });
};
/**
 * Verifies the verification code.
 *
 * @param data - The verification request payload.
 * @param isShow - Whether to show loading indicator.
 * @returns A promise that resolves to an ApiResponse.
 */
export const verifyCode = async (data) => {
    return axiosInstance.post(HttpApi.verifyCode, data);
};
/**
 * 退出登录
 *
 * @returns Promise<ApiResponse<null>> - 退出登录的API响应
 */
export const logout = (data) => {
    return axiosInstance.post(HttpApi.logout, data);
};
/**
 * Updates the user's password.
 *
 * @param data - The update password request payload.
 * @returns A promise that resolves to an ApiResponse.
 */
export const resetPassword = (data) => {
    return axiosInstance.post(HttpApi.forgetPassword, data);
};
/**
 * Updates the user's nickname.
 *
 * @param newNickName - The new nickname for the user.
 * @returns A promise that resolves to an ApiResponse.
 */
export const updateNickName = (newNickName) => {
    return axiosInstance.get(HttpApi.changeNickName.replace('%s', newNickName));
};
/**
 * Deletes the user's account.
 *
 * @returns A promise that resolves to an ApiResponse.
 */
export const deleteAccount = () => {
    return axiosInstance.delete(HttpApi.deleteUser);
};
/**
 * Updates the user's email address.
 *
 * @param email - The new email address.
 * @param code - The verification code.
 * @returns A promise that resolves to an ApiResponse.
 */
export const updateEmail = (email, code) => {
    return axiosInstance.post(HttpApi.changeEmail, { email, code });
};
/**
 * Updates the user's password when logged in.
 *
 * @param oldPassword - The user's current password.
 * @param newPassword - The new password to set.
 * @returns A promise that resolves to an ApiResponse.
 */
export const updatePassword = (data) => {
    return axiosInstance.post(HttpApi.changePassword, data);
};
/**
 * Updates the user's notification email.
 *
 * @param isTourists - Indicates if the user is a tourist user.
 * @param noticeEmail - The new notification email address.
 * @param code - The verification code (required for non-tourist users).
 * @returns A promise that resolves to an ApiResponse.
 */
export const updateNotificationEmail = (isTourists, noticeEmail, code) => {
    const params = isTourists ? { noticeEmail } : { noticeEmail, code };
    return axiosInstance.post(HttpApi.updateNoticeEmail, params);
};
/**
 * Updates the user's mail notification switches.
 *
 * @param transcribeFlag - Flag to enable/disable transcribe notifications.
 * @param translateFlag - Flag to enable/disable translate notifications.
 * @returns A promise that resolves to an ApiResponse.
 */
export const updateMailNotificationSwitch = (transcribeFlag, translateFlag) => {
    return axiosInstance.post(HttpApi.updateMailNotificationSwitch, {
        transcribeFlag,
        translateFlag,
    });
};
