import { createI18n } from 'vue-i18n';
const messages = {
    en: {
        header: {
            home: 'Home',
            pricing: 'Pricing',
            download: 'App Download',
            login: 'Login/Register',
        },
        home: {
            hero: {
                title: 'Professional Voice Transcription Assistant, Powered by AI',
                subtitle: 'Automatically transcribe your meetings, interviews, lectures, and media content. Fast, accurate, and affordable.',
                cta: 'Try for Free',
                freeTrial: 'Includes 120 minutes of free transcription',
            },
            features: {
                title: 'Features',
                card1: {
                    title: 'Transcribe Audio Files',
                    description: 'Support for multiple audio formats, quickly and accurately transcribe audio files.',
                },
                card2: {
                    title: 'Transcribe Online Meetings',
                    description: 'Transcribe Zoom, Google Meet, and Microsoft Teams online meetings with AI meeting assistant.',
                },
                card3: {
                    title: 'Smart Editor',
                    description: 'Easily proofread and edit transcribed content with the smart text editor.',
                },
                card4: {
                    title: 'Rich Export Options',
                    description: 'Export transcribed text content to multiple file formats (TXT, SRT, Word, etc.).',
                },
            },
            services: {
                title: 'Most Accurate Voice Transcription Service',
                description: 'Our voice transcription service uses state-of-the-art speech recognition technology to ensure accuracy and speed. Suitable for meetings, lectures, interviews, and more.',
            },
        },
    },
    zh: {
        header: {
            home: '首页',
            pricing: '价格',
            download: 'App下载',
            login: '登录/注册',
        },
        home: {
            hero: {
                title: '专业的语音转录助手，由 AI 提供支持',
                subtitle: '自动转录您的会议、采访、讲座和媒体内容。快速、准确且经济实惠。',
                cta: '免费试用',
                freeTrial: '包括 120 分钟免费转录',
            },
            features: {
                title: '特征',
                card1: {
                    title: '转录音频文件',
                    description: '支持多种音频格式，快速准确地转录音频文件。',
                },
                card2: {
                    title: '转录在线会议',
                    description: '通过 AI 会议助手，将 Zoom、Google Meet 和 Microsoft Teams 在线会议转录。',
                },
                card3: {
                    title: '智能编辑器',
                    description: '使用智能文本编辑器，轻松校对和编辑转录内容。',
                },
                card4: {
                    title: '丰富的导出选项',
                    description: '将文字转录内容导出为多种文件格式（TXT、SRT、Word 等）。',
                },
            },
            services: {
                title: '最准确的语音转录服务',
                description: '我们的语音转录服务使用最先进的语音识别技术，确保准确性和速度。适用于会议、讲座、采访等多种场景。',
            },
        },
    },
    ja: {
        header: {
            home: 'ホーム',
            pricing: '価格',
            download: 'アプリダウンロード',
            login: 'ログイン/登録',
        },
        home: {
            hero: {
                title: 'AIを活用したプロフェッショナルな音声文字起こしアシスタント',
                subtitle: '会議、インタビュー、講義、メディアコンテンツを自動で文字起こし。高速、正確、そして手頃な価格。',
                cta: '無料トライアル',
                freeTrial: '120分の無料文字起こしを含む',
            },
            features: {
                title: '機能',
                card1: {
                    title: '音声ファイルの文字起こし',
                    description: '複数の音声フォーマットに対応し、素早く正確に音声ファイルを文字起こし。',
                },
                card2: {
                    title: 'オンライン会議の文字起こし',
                    description: 'AI会議アシスタントを使用して、Zoom、Google Meet、Microsoft Teamsのオンライン会議を文字起こし。',
                },
                card3: {
                    title: 'スマートエディター',
                    description: 'スマートテキストエディターで、文字起こしされたコンテンツを簡単に校正・編集。',
                },
                card4: {
                    title: '豊富なエクスポートオプション',
                    description: '文字起こしされたテキストコンテンツを複数のファイル形式（TXT、SRT、Wordなど）にエクスポート。',
                },
            },
            services: {
                title: '最も正確な音声文字起こしサービス',
                description: '当社の音声文字起こしサービスは、最先端の音声認識技術を使用して、正確性とスピードを確保します。会議、講義、インタビューなど、さまざまな場面に適しています。',
            },
        },
    },
};
const i18n = createI18n({
    legacy: false,
    locale: 'zh',
    fallbackLocale: 'en',
    messages,
});
export default i18n;
