import { defineComponent, computed } from 'vue';
import { useStore } from '@/store/userStore'; // Ensure the module path is correct
export default defineComponent({
    name: 'NotificationEmailManage',
    setup() {
        const store = useStore();
        const userInfo = computed(() => store.state.auth.user);
        const notificationSettings = computed(() => ({
            transcriptionNotification: userInfo.value?.transcribeFlag ?? true,
            translationNotification: userInfo.value?.translateFlag ?? false,
        }));
        return {
            notificationSettings, // 将通知设置暴露给模板使用
        };
    },
});
