import folderIcon from '@/assets/file_type_folder.png';
import videoIcon from '@/assets/file_type_video.png';
import audioIcon from '@/assets/file_type_audio.png';
import meetingIcon from '@/assets/file_type_meeting.png';
/**
 * Returns the MIME type for a given file extension.
 *
 * @param extension - The file extension (e.g., 'jpg', 'png', 'pdf').
 * @returns The corresponding MIME type or null if the extension is not recognized.
 */
export const getMediaType = (extension) => {
    const mimeTypes = {
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        gif: 'image/gif',
        bmp: 'image/bmp',
        webp: 'image/webp',
        mp4: 'video/mp4',
        mkv: 'video/x-matroska',
        webm: 'video/webm',
        mp3: 'audio/mpeg',
        wav: 'audio/wav',
        ogg: 'audio/ogg',
        pdf: 'application/pdf',
        doc: 'application/msword',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        xls: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ppt: 'application/vnd.ms-powerpoint',
        pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        txt: 'text/plain',
        csv: 'text/csv',
        json: 'application/json',
        xml: 'application/xml',
        zip: 'application/zip',
        rar: 'application/x-rar-compressed',
        '7z': 'application/x-7z-compressed',
    };
    return mimeTypes[extension.toLowerCase()] || null;
};
export const getFileIcon = (type) => {
    if (!type)
        return folderIcon;
    if (type === 'folder')
        return folderIcon;
    if (type === 'file')
        return audioIcon;
    if (type.includes('video'))
        return videoIcon;
    if (type.includes('audio'))
        return audioIcon;
    if (type.includes('meeting'))
        return meetingIcon;
    return audioIcon;
};
export const getStatusColor = (status) => {
    switch (status) {
        case 'translating':
            return 'blue';
        case 'translated':
            return 'green';
        case 'translatefail':
            return 'red';
        default:
            return 'default';
    }
};
