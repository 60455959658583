import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue';
import { Message } from '@arco-design/web-vue';
import { IconDown, IconEmail, IconLock } from '@arco-design/web-vue/es/icon';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default defineComponent({
    name: 'ForgetPasswordPage',
    components: {
        IconDown,
        IconEmail,
        IconLock,
    },
    setup() {
        const router = useRouter();
        const { locale } = useI18n();
        const store = useStore();
        const currentLanguage = computed(() => locale.value === 'zh' ? '简体中文' : 'English');
        const changeLanguage = (value) => {
            locale.value = value;
        };
        const currentStep = ref(1);
        const form = reactive({
            email: '',
            verificationCode: '',
            password: '',
            confirmPassword: '',
        });
        const isCountingDown = ref(false);
        const countdown = ref(60);
        const countdownText = computed(() => {
            return isCountingDown.value
                ? `${countdown.value}s 后重新获取`
                : '获取验证码';
        });
        let timer = null;
        const newVerificationCode = ref(''); // 存储第一次验证返回的新 code
        const getVerificationCode = async () => {
            if (form.email === '') {
                Message.warning('请输入邮箱');
                return;
            }
            if (!isValidEmail(form.email)) {
                Message.warning('请输入正确的邮箱格式');
                return;
            }
            try {
                const response = await store.dispatch('auth/sendVerificationCode', {
                    email: form.email,
                    type: 'forgetPassword',
                });
                if (response.code === 0) {
                    Message.success('验证码已发送至您的邮箱');
                    isCountingDown.value = true;
                    countdown.value = 60;
                    timer = window.setInterval(() => {
                        if (countdown.value > 1) {
                            countdown.value--;
                        }
                        else {
                            clearInterval(timer);
                            timer = null;
                            isCountingDown.value = false;
                        }
                    }, 1000);
                }
                else {
                    throw new Error(response.msg || '发送验证码失败');
                }
            }
            catch (error) {
                if (error instanceof Error) {
                    Message.error(error.message || '发送验证码失败');
                }
                else {
                    Message.error('发送验证码失败');
                }
            }
        };
        const verifyCode = async () => {
            if (!form.email || !form.verificationCode) {
                Message.warning('请填写完整的信息');
                return;
            }
            try {
                console.log('Verifying code...'); // 添加日志：开始验证
                const response = await store.dispatch('auth/verifyCode', {
                    email: form.email,
                    code: form.verificationCode,
                });
                console.log('Verification response:', response); // 添加日志：输出响应
                if (response) {
                    newVerificationCode.value = response; // 存储新的验证码
                    Message.success('验证成功');
                    console.log('Verification successful, new code:', newVerificationCode.value); // 添加日志：验证成功
                    currentStep.value = 2;
                    console.log('Current step updated:', currentStep.value); // 添加日志：步骤更新
                }
                else {
                    console.log('Verification failed: No data in response'); // 添加日志：验证失败
                    throw new Error('验证失败：响应中没有数据');
                }
            }
            catch (error) {
                console.error('Verification error:', error); // 添加日志：错误详情
                if (error instanceof Error) {
                    Message.error(error.message || '验证失败');
                }
                else {
                    Message.error('验证失败');
                }
            }
        };
        const handleSubmit = async () => {
            if (currentStep.value === 1) {
                await verifyCode();
            }
            else if (currentStep.value === 2) {
                if (!form.password || !form.confirmPassword) {
                    Message.warning('请填写新密码');
                    return;
                }
                if (form.password !== form.confirmPassword) {
                    Message.error('两次输入的密码不一致');
                    return;
                }
                try {
                    const response = await store.dispatch('auth/resetPassword', {
                        email: form.email,
                        code: newVerificationCode.value,
                        newPassword: form.password,
                    });
                    if (response.code === 0) {
                        Message.success('密码重置成功');
                        router.push({
                            path: '/login',
                            query: { email: form.email },
                        });
                    }
                    else {
                        throw new Error(response.msg || '重置密码失败');
                    }
                }
                catch (error) {
                    if (error instanceof Error) {
                        Message.error(error.message || '重置密码失败');
                    }
                    else {
                        Message.error('重置密码失败');
                    }
                }
            }
        };
        const isValidEmail = (email) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        };
        const handleBack = () => {
            if (currentStep.value > 1) {
                currentStep.value--;
            }
            else {
                router.push('/login');
            }
        };
        onUnmounted(() => {
            if (timer !== null) {
                clearInterval(timer);
            }
        });
        return {
            currentStep,
            form,
            isCountingDown,
            countdownText,
            getVerificationCode,
            handleSubmit,
            handleBack,
            currentLanguage,
            changeLanguage,
            verifyCode,
            newVerificationCode,
        };
    },
});
