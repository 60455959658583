import { createApp } from 'vue';
import App from './App.vue';
import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';
import router from './router';
import i18n from './i18n';
import store from './store';
// Set the primary color using CSS variables
document.documentElement.style.setProperty('--color-primary', '#7a52f4');
const app = createApp(App);
// 注册 Vuex Store 在其他插件之前
app.use(store);
app.use(ArcoVue);
app.use(router);
app.use(i18n);
// 注册 Service Worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/service-worker.js')
            .then((registration) => {
            console.log('ServiceWorker registered: ', registration);
        })
            .catch((error) => {
            console.error('ServiceWorker registration failed: ', error);
        });
    });
}
// 检查现有的 auth token
const token = localStorage.getItem('auth_token');
if (token) {
    store.commit('auth/SET_TOKEN', token);
    store.commit('auth/SET_LOGGED_IN', true);
    // 根据 token 获取用户数据
    store
        .dispatch('auth/getUserInfo')
        .then(() => {
        console.log('用户信息加载成功');
    })
        .catch(() => {
        console.error('获取用户信息失败，清除无效 token');
        store.commit('auth/CLEAR_USER');
        localStorage.removeItem('auth_token');
        router.push('/login');
    });
}
// Mount the Vue app to the DOM
app.mount('#app');
