import { defineComponent, reactive, computed, ref, onUnmounted, onMounted, } from 'vue';
import { Message } from '@arco-design/web-vue';
import { useI18n } from 'vue-i18n';
import { IconDown, IconEmail, IconLock } from '@arco-design/web-vue/es/icon';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default defineComponent({
    name: 'RegisterPage',
    components: {
        IconDown,
        IconEmail,
        IconLock,
    },
    setup() {
        const { locale } = useI18n();
        const router = useRouter();
        const store = useStore();
        const form = reactive({
            email: '',
            verificationCode: '',
            password: '',
            confirmPassword: '',
            agreed: false,
        });
        // Clear form fields on component mount
        onMounted(() => {
            form.email = '';
            form.verificationCode = '';
            form.password = '';
            form.confirmPassword = '';
            form.agreed = false;
        });
        const currentLanguage = computed(() => locale.value === 'zh' ? '简体中文' : 'English');
        const changeLanguage = (value) => {
            locale.value = value;
        };
        const isCountingDown = ref(false);
        const countdown = ref(60);
        const countdownText = computed(() => {
            return isCountingDown.value
                ? `${countdown.value}s 后重新获取`
                : '获取验证码';
        });
        let timer = null;
        const getVerificationCode = async () => {
            if (form.email === '') {
                Message.warning('请输入邮箱');
                return;
            }
            try {
                await store.dispatch('auth/sendVerificationCode', {
                    email: form.email,
                    type: 'register',
                });
                Message.success('验证码已发送至您的邮箱');
                // Start countdown
                isCountingDown.value = true;
                countdown.value = 60;
                timer = window.setInterval(() => {
                    if (countdown.value > 1) {
                        countdown.value--;
                    }
                    else {
                        clearInterval(timer);
                        timer = null;
                        isCountingDown.value = false;
                    }
                }, 1000);
            }
            catch (error) {
                console.error('Failed to get verification code:', error);
            }
        };
        const handleSubmit = async () => {
            if (!form.email ||
                !form.verificationCode ||
                !form.password ||
                !form.confirmPassword) {
                Message.warning('请填写完整的注册信息');
                return;
            }
            if (form.password !== form.confirmPassword) {
                Message.error('两次输入的密码不一致');
                return;
            }
            if (!form.agreed) {
                Message.warning('请阅读并同意《用户协议》和《隐私政策》');
                return;
            }
            try {
                const response = await store.dispatch('auth/register', {
                    email: form.email,
                    code: form.verificationCode,
                    password: form.password,
                });
                if (response.code === 0) {
                    Message.success('注册成功');
                    // Redirect to the main page or dashboard
                    router.push('/main');
                }
            }
            catch (error) {
                console.error('Registration failed:', error);
            }
        };
        const openUserAgreement = () => {
            window.open('/user-agreement', '_blank');
        };
        const openPrivacyPolicy = () => {
            window.open('/privacy-policy', '_blank');
        };
        onUnmounted(() => {
            if (timer !== null) {
                clearInterval(timer);
            }
        });
        return {
            form,
            handleSubmit,
            getVerificationCode,
            currentLanguage,
            changeLanguage,
            isCountingDown,
            countdownText,
            openUserAgreement,
            openPrivacyPolicy,
        };
    },
});
