import { createStore } from 'vuex';
import { authModule } from './auth';
import fileModule from './fileStore';
import fileDetailModule from './fileDetailStore';
const store = createStore({
    modules: {
        auth: authModule,
        fileStore: fileModule,
        fileDetail: fileDetailModule, // 确保这里是 fileDetail
    },
});
export default store;
