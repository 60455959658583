import { getTranscribedFilesByCode, uploadFile } from '@/api/file_folder';
import { Message } from '@arco-design/web-vue';
const fileDetailModule = {
    namespaced: true,
    state: () => ({
        fileName: '',
        status: '',
        statusText: '',
        duration: 0,
        createTime: 0,
        expiredTime: 0,
        previewUrl: '',
        transcriptSegments: [],
        code: '',
        translatedFileCode: '',
    }),
    getters: {
        uniqueSpeakers: (state) => {
            return [
                ...new Set(state.transcriptSegments.map((segment) => segment.speaker)),
            ];
        },
    },
    mutations: {
        SET_FILE_DETAIL(state, payload) {
            Object.assign(state, payload);
        },
        SET_TRANSCRIPT_SEGMENTS(state, segments) {
            state.transcriptSegments = segments;
        },
    },
    actions: {
        async fetchFileDetail({ commit }, params) {
            commit('SET_FILE_DETAIL', {
                fileName: params.name,
                status: params.status,
                statusText: params.status === 'processing' ? '处理中' : '已完成',
                duration: Number(params.duration) || 0,
                createTime: Number(params.createTime) || 0,
                expiredTime: Number(params.expiredTime) || 0,
                previewUrl: params.previewUrl,
                code: params.code,
                translatedFileCode: params.translatedFileCode,
            });
            try {
                console.log('Fetching file details with params:', params);
                const response = await getTranscribedFilesByCode(params.translatedFileCode);
                console.log('Received response:', response);
                if (response.data && Array.isArray(response.data.transcriptions)) {
                    commit('SET_TRANSCRIPT_SEGMENTS', response.data.transcriptions);
                }
                else {
                    console.error('Unexpected response data:', response.data);
                    throw new Error('Unexpected response data format');
                }
            }
            catch (error) {
                console.error('Error fetching file details:', error);
                // You can add more specific error handling here if needed
                throw error;
            }
        },
        async saveFileEdits({ state, commit }, updatedTranscriptions) {
            try {
                const updatedEntity = {
                    transcriptions: updatedTranscriptions,
                };
                await uploadFile(updatedEntity, state.translatedFileCode);
                commit('SET_TRANSCRIPT_SEGMENTS', updatedTranscriptions);
                Message.success('保存成功');
            }
            catch (error) {
                console.error('Error saving edits:', error);
                Message.error('保存失败');
                throw error;
            }
        },
    },
};
export default fileDetailModule;
