import { defineComponent, computed, ref, onMounted } from 'vue';
import { IconEdit } from '@arco-design/web-vue/es/icon';
import { Message } from '@arco-design/web-vue';
import { useRouter } from 'vue-router';
import { useStore } from '@/store/userStore';
export default defineComponent({
    name: 'AccountInfo',
    components: { IconEdit },
    setup() {
        const store = useStore();
        const router = useRouter();
        const userInfo = computed(() => store.state.auth.user);
        // 计算头像文本，取名字的首个字符
        const avatarText = computed(() => {
            return userInfo.value?.userName?.charAt(0) || '';
        });
        // 头像的背景颜色：紫色
        const avatarBackgroundColor = '#7a52f4'; // 紫色
        // 密码字段（仅用于显示，不存储在用户信息中）
        const password = ref('********');
        /**
         * 修改密码功能
         */
        const changePassword = () => {
            // 实现修改密码的逻辑
            Message.success('密码修改功能待实现');
            // 例如，跳转到修改密码页面：
            // router.push('/change-password')
        };
        /**
         * 编辑名字功能
         */
        const editName = () => {
            // 实现编辑名字的逻辑
            Message.info('编辑名字功能待实现');
            // 例如，打开编辑对话框：
            // showEditNameDialog.value = true
        };
        // 在组件挂载时检查用户信息
        onMounted(async () => {
            if (!store.getters['auth/isLoggedIn']) {
                router.replace('/login');
            }
            else if (!store.state.auth.user) {
                try {
                    await store.dispatch('auth/getUserInfo');
                }
                catch (error) {
                    console.error('获取用户信息失败:', error);
                    router.replace('/login');
                }
            }
        });
        return {
            userInfo,
            avatarText,
            avatarBackgroundColor,
            password,
            changePassword,
            editName,
        };
    },
});
