class HttpApi {
    static deleteUser = '/api/user/hard-del/myself';
    static login = '/api/user/login';
    static touristsLogin = '/api/user/register-by-tourists/%s';
    static register = '/api/user/register-by-email';
    static sendSms = '/api/email/send-code';
    static userInfo = '/api/user/myself';
    static changePassword = '/api/user/changePwd';
    static verifyCode = '/api/user/verify-forget-psw-code';
    static forgetPassword = '/api/user/forget-password';
    static changeEmail = '/api/user/changeEmail';
    static updateNoticeEmail = '/api/user/update-notice-email';
    static updateMailNotificationSwitch = '/api/email/updateMailNotificationSwitchs';
    static changeNickName = '/api/user/update-nickname/%s';
    static logout = '/api/user/logout';
    static addFolder = '/api/folder/add-folder';
    static deleteFile = '/api/file/delete/%s';
    static deleteFolder = '/api/folder/delete/%s';
    static renameFolderOrFile = '/api/file/rename';
    static moveFolderOrFile = '/api/file/move';
    static getAllFoldersTree = '/api/folder/get-all-folders/root';
    static getAllFileAndFolderList = '/api/folder/get-all-folder-file-by-user/%s';
    static getCurrentFolderList = '/api/folder/get-child-folder-by-code';
    static getCurrentFileAndFolderList = '/api/folder/get-folder-file-by-code';
    static getFirstFilesAndFolders = '/api/folder/get-first-file-and-folder';
    static getDashboardFilesAndFolders = '/api/folder/get-user-latest-file-list';
    static fileUpload = '/api/file/upload';
    static fileSubmit = '/api/file/submit-transcription-file';
    static fileDownload = '/api/file/download/%s';
    static fireRefresh = '/api/file/refresh-url/%s';
    static fileTranscribeByUrl = '/api/translate/translate/%s';
    static fileTranscribeByCode = '/api/translate/translate-file-by-fast-whisper';
    static fileReupload = '/api/file/reupload-translated-file';
    static getLanguageList = '/api/static/data/get/translate-language';
    static getFeedbackList = '/api/feedback/my-feedbacks';
    static feedback = '/api/feedback/add';
    static feedbackDetail = '/api/feedback/detail/%s';
    static checkUpdate = '/api/portal-download/check-latest-version';
    static registerValidate = '/user/v1/register/validate';
    static refreshToken = '/user/v1/refreshToken/%s';
    static awsSts = '/res/v3/awsSts';
    static getSubscriptionInfo = '/api/combo/get-combo-by-self';
}
export default HttpApi;
