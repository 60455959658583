// Script 部分保持不变
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
export default defineComponent({
    name: 'Header',
    components: {
    // IconDown,
    },
    setup() {
        const route = useRoute();
        const { t, locale } = useI18n();
        const router = useRouter();
        const currentRoute = computed(() => route.name);
        const currentLanguage = computed(() => {
            switch (locale.value) {
                case 'zh':
                    return '简体中文';
                case 'en':
                    return 'English';
                case 'ja':
                    return '日本語';
                default:
                    return '简体中文';
            }
        });
        const changeLanguage = (value) => {
            locale.value = value;
        };
        const goToLogin = () => {
            router.push('/login');
        };
        return {
            currentRoute,
            currentLanguage,
            changeLanguage,
            goToLogin,
        };
    },
});
