import { login as apiLogin, register as apiRegister, logout as apiLogout, getUserInfo as apiGetUserInfo, sendVerificationCode, resetPassword, updatePassword, updateEmail, verifyCode, } from '@/api/auth';
import { getDeviceInfo } from '@/utils/device';
export const authModule = {
    namespaced: true,
    state: () => ({
        isLoggedIn: false,
        user: null,
        token: null,
    }),
    mutations: {
        /**
         * 设置登录状态。
         *
         * @param state - 当前状态。
         * @param value - 新的登录状态。
         */
        SET_LOGGED_IN(state, value) {
            state.isLoggedIn = value;
        },
        /**
         * 设置用户信息。
         *
         * @param state - 当前状态。
         * @param user - 用户数据。
         */
        SET_USER(state, user) {
            state.user = user;
        },
        /**
         * 设置认证令牌。
         *
         * @param state - 当前状态。
         * @param token - 认证令牌。
         */
        SET_TOKEN(state, token) {
            state.token = token;
        },
        /**
         * 清除 Vuex store 中的用户数据。
         *
         * @param state - 当前状态。
         */
        CLEAR_USER(state) {
            state.user = null;
            state.token = null;
            state.isLoggedIn = false;
        },
    },
    actions: {
        /**
         * 从本地存储初始化认证状态。
         *
         * @param context - Vuex action 上下文。
         */
        initAuth({ commit, dispatch }) {
            const token = localStorage.getItem('auth_token');
            if (token) {
                commit('SET_TOKEN', token);
                commit('SET_LOGGED_IN', true);
                dispatch('getUserInfo');
            }
        },
        /**
         * 处理用户登录。
         *
         * @param context - Vuex action 上下文。
         * @param loginData - 登录请求负载。
         * @returns API 响应。
         */
        async login({ commit }, payload) {
            try {
                const response = await apiLogin(payload);
                if (response.code === 0) {
                    commit('SET_TOKEN', response.data.token);
                    commit('SET_LOGGED_IN', true);
                    commit('SET_USER', response.data.userInfo);
                    localStorage.setItem('auth_token', response.data.token);
                }
                return response;
            }
            catch (error) {
                if (error instanceof Error) {
                    console.error('登录错误:', error.message);
                    throw new Error(error.message || '登录失败');
                }
                console.error('登录错误: 未知错误');
                throw new Error('登录失败');
            }
        },
        /**
         * 处理用户注册。
         *
         * @param context - Vuex action 上下文。
         * @param payload - 注册请求负载。
         * @returns API 响应。
         */
        async register({ commit }, payload) {
            try {
                // 获取设备信息以补充注册请求
                const deviceInfo = await getDeviceInfo();
                // 调用注册API，并传递完整的注册数据
                const response = await apiRegister({
                    email: payload.email,
                    code: payload.code,
                    password: payload.password,
                    deviceId: deviceInfo.deviceId,
                });
                if (response.code === 0) {
                    commit('SET_TOKEN', response.data.token);
                    commit('SET_LOGGED_IN', true);
                    commit('SET_USER', response.data.userInfo);
                    localStorage.setItem('auth_token', response.data.token);
                }
                return response;
            }
            catch (error) {
                if (error instanceof Error) {
                    console.error('注册错误:', error.message);
                    throw new Error(error.message || '注册失败');
                }
                console.error('注册错误: 未知错误');
                throw new Error('注册失败');
            }
        },
        /**
         * 处理用户退出登录。
         *
         * @param context - Vuex action 上下文。
         * @returns Promise<void>
         */
        async logout({ commit, }) {
            try {
                const deviceInfo = await getDeviceInfo();
                const response = await apiLogout(deviceInfo);
                if (response.code === 0) {
                    commit('CLEAR_USER');
                    localStorage.removeItem('auth_token');
                }
                else {
                    throw new Error(response.msg || '退出登录失败');
                }
            }
            catch (error) {
                if (error instanceof Error) {
                    console.error('退出登录错误:', error.message);
                    throw new Error(error.message || '退出登录失败');
                }
                console.error('退出登录错误: 未知错误');
                throw new Error('退出登录失败');
            }
        },
        /**
         * 获取用户信息。
         *
         * @param context - Vuex action 上下文。
         * @returns API 响应。
         */
        async getUserInfo({ commit, }) {
            try {
                const deviceInfo = await getDeviceInfo();
                const response = await apiGetUserInfo(deviceInfo.deviceId);
                if (response.code === 0) {
                    commit('SET_USER', response.data);
                    return response;
                }
                else {
                    throw new Error(response.msg || '获取用户信息失败');
                }
            }
            catch (error) {
                if (error instanceof Error) {
                    console.error('获取用户信息失败:', error.message);
                    throw new Error(error.message || '获取用户信息失败');
                }
                console.error('获取用户信息错误: 未知错误');
                throw new Error('获取用户信息失败');
            }
        },
        /**
         * 发送验证码。
         *
         * @param context - Vuex action 上下文。
         * @param payload - 包含email和验证码类型的对象。
         * @returns API 响应。
         */
        async sendVerificationCode({ commit }, payload) {
            try {
                const response = await sendVerificationCode(payload.email, payload.type);
                return response;
            }
            catch (error) {
                if (error instanceof Error) {
                    console.error('发送验证码错误:', error.message);
                    throw new Error(error.message || '发送验证码失败');
                }
                console.error('发送验证码错误: 未知错误');
                throw new Error('发送验证码失败');
            }
        },
        /**
         * Verifies the verification code.
         *
         * @param context - Vuex action context.
         * @param payload - Object containing email, code, callback function, and isShow flag.
         */
        async verifyCode({ commit }, payload) {
            const { email, code } = payload;
            try {
                const response = await verifyCode({ email, code });
                if (response.code === 0) {
                    return response.data;
                }
                else {
                    throw new Error(response.msg || '验证失败');
                }
            }
            catch (error) {
                console.error('验证码验证错误:', error);
                return null;
            }
        },
        /**
         * 重置密码。
         *
         * @param context - Vuex action 上下文。
         * @param payload - 包含重置密码请求数据的对象。
         * @returns API 响应。
         */
        async resetPassword({ commit }, payload) {
            try {
                const response = await resetPassword(payload);
                return response;
            }
            catch (error) {
                if (error instanceof Error) {
                    console.error('重置密码错误:', error.message);
                    throw new Error(error.message || '重置密码失败');
                }
                console.error('重置密码错误: 未知错误');
                throw new Error('重置密码失败');
            }
        },
        /**
         * 修改密码。
         *
         * @param context - Vuex action 上下文。
         * @param payload - 包含旧密码和新密码的对象。
         * @returns API 响应。
         */
        async updatePassword({ commit }, payload) {
            try {
                const response = await updatePassword(payload);
                return response;
            }
            catch (error) {
                if (error instanceof Error) {
                    console.error('修改密码错误:', error.message);
                    throw new Error(error.message || '修改密码失败');
                }
                console.error('修改密码错误: 未知错误');
                throw new Error('修改密码失败');
            }
        },
        /**
         * 修改邮箱。
         *
         * @param context - Vuex action 上下文。
         * @param payload - 包含新邮箱和验证码的对象。
         * @returns API 响应。
         */
        async changeEmail({ commit, state }, payload) {
            try {
                const response = await updateEmail(payload.newEmail, payload.verificationCode);
                if (response.code === 0 && state.user) {
                    commit('SET_USER', { ...state.user, email: payload.newEmail });
                }
                return response;
            }
            catch (error) {
                if (error instanceof Error) {
                    console.error('修改邮箱错误:', error.message);
                    throw new Error(error.message || '修改邮箱失败');
                }
                console.error('修改邮箱错误: 未知错误');
                throw new Error('修改邮箱失败');
            }
        },
    },
    getters: {
        /**
         * 获取登录状态。
         *
         * @param state - 当前状态。
         * @returns 登录状态。
         */
        isLoggedIn: (state) => state.isLoggedIn,
        /**
         * 获取当前用户信息。
         *
         * @param state - 当前状态。
         * @returns 用户信息。
         */
        currentUser: (state) => state.user,
        /**
         * 获取认证令牌。
         *
         * @param state - 当前状态。
         * @returns 认证令牌。
         */
        authToken: (state) => state.token,
    },
};
