import { defineComponent, ref, computed, onMounted, watch, provide } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { IconHome, IconFile, IconUser, IconMessage, IconExport, IconDown, } from '@arco-design/web-vue/es/icon';
import { useI18n } from 'vue-i18n';
import { Message } from '@arco-design/web-vue';
import { useStore } from '@/store/userStore';
import UserInfoDropdown from '@/components/UserInfoDropdown.vue';
import ImportMediaModal from '@/components/ImportMediaModal.vue';
export default defineComponent({
    name: 'MainPage',
    components: {
        IconHome,
        IconFile,
        IconUser,
        IconMessage,
        IconExport,
        IconDown,
        UserInfoDropdown,
        ImportMediaModal,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const selectedLanguage = ref('zh');
        const defaultOpenKeys = ref([]);
        const store = useStore();
        const selectedMenuKey = ref(localStorage.getItem('selectedMenuKey') || 'dashboard');
        const openKeys = ref(JSON.parse(localStorage.getItem('openKeys') || '[]'));
        watch(openKeys, (newOpenKeys) => {
            localStorage.setItem('openKeys', JSON.stringify(newOpenKeys));
        });
        const handleMenuClick = (key) => {
            selectedMenuKey.value = key;
            localStorage.setItem('selectedMenuKey', key);
            switch (key) {
                case 'dashboard':
                    router.push('/main/dashboard');
                    break;
                case 'files':
                    router.push('/main/file-manager');
                    break;
                case 'feedback':
                    router.push('/main/feedback');
                    break;
                case 'notifications':
                    router.push('/main/notification-email-manage');
                    break;
                case 'account':
                    router.push('/main/account-info');
                    break;
            }
        };
        const { t, locale } = useI18n();
        const currentLanguage = computed(() => locale.value === 'zh' ? '简体中文' : 'English');
        const changeLanguage = (value) => {
            locale.value = value;
        };
        // Computed property to determine if the right sider should be shown
        const showRightSider = computed(() => {
            return ['Dashboard', 'FileManager', 'SubFolder'].includes(route.name);
        });
        const isLoggedIn = computed(() => store.state.auth.isLoggedIn);
        onMounted(async () => {
            if (!isLoggedIn.value) {
                router.replace('/login');
            }
            else if (!store.state.auth.user) {
                try {
                    await store.dispatch('auth/getUserInfo');
                }
                catch (error) {
                    console.error('获取用户信息失败:', error);
                    router.replace('/login');
                }
            }
        });
        const handleLogout = async () => {
            try {
                await store.dispatch('auth/logout');
                Message.success('退出登录成功');
                router.replace('/login');
            }
            catch (error) {
                console.error('退出登录失败:', error);
                Message.error('退出登录失败');
            }
        };
        const importModalVisible = ref(false);
        const showImportModal = () => {
            importModalVisible.value = true;
        };
        const handleImport = (importData) => {
            console.log('开始导入', importData);
            // 这里可以添加其他处理导入的逻辑，如果需要的话
        };
        const handleImportSuccess = () => {
            // 创建一个事件来通知需要刷新列表
            window.dispatchEvent(new CustomEvent('refreshFileList'));
        };
        // 提供一个方法给子组件调用，用于显示导入模态框
        provide('showImportModal', showImportModal);
        return {
            selectedLanguage,
            defaultOpenKeys,
            handleMenuClick,
            currentLanguage,
            changeLanguage,
            showRightSider,
            handleLogout,
            selectedMenuKey,
            openKeys,
            importModalVisible,
            showImportModal,
            handleImport,
            handleImportSuccess,
        };
    },
});
