import { computed, defineComponent, onMounted, ref, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { Message } from '@arco-design/web-vue';
import { IconMore } from '@arco-design/web-vue/es/icon';
import { useRouter } from 'vue-router';
import { getFileIcon, getStatusColor } from '@/utils/utils';
export default defineComponent({
    name: 'Dashboard',
    components: { IconMore },
    setup() {
        const router = useRouter();
        const store = useStore();
        const loading = ref(false);
        const fetchFiles = async () => {
            loading.value = true;
            try {
                await store.dispatch('fileStore/fetchDashboardFiles');
            }
            catch (error) {
                console.error('Error fetching dashboard files:', error);
                Message.error('Failed to fetch dashboard files. Please try again later.');
            }
            finally {
                loading.value = false;
            }
        };
        const refreshFileList = () => {
            console.log('Refreshing dashboard file list');
            fetchFiles();
        };
        onMounted(() => {
            console.log('Dashboard mounted, fetching files...');
            fetchFiles();
            window.addEventListener('refreshFileList', refreshFileList);
        });
        onUnmounted(() => {
            window.removeEventListener('refreshFileList', refreshFileList);
        });
        const columns = [
            { title: '文件名称', dataIndex: 'name', slotName: 'name' },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                render: ({ record }) => {
                    const date = new Date(record.createTime || 0);
                    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
                },
            },
            {
                title: '保留时长',
                dataIndex: 'expiredTime',
                render: ({ record }) => {
                    if (!record.expiredTime || !record.createTime)
                        return '-';
                    const days = Math.floor((record.expiredTime - record.createTime) / (24 * 60 * 60 * 1000));
                    return `${days} 天`;
                },
            },
            { title: '操作', dataIndex: 'actions', slotName: 'actions', width: 80 },
        ];
        const dashboardFiles = computed(() => store.getters['fileStore/dashboardFiles']);
        const dashboardTotalFiles = computed(() => store.getters['fileStore/dashboardTotalFiles']);
        const handleFileClick = (file) => {
            router.push({
                name: 'FileDetail',
                query: {
                    code: file.code || '',
                    translatedFileCode: file.translatedFileCode || '',
                    name: file.name || '',
                    status: file.status || '',
                    duration: file.duration?.toString() || '',
                    createTime: file.createTime?.toString() || '',
                    expiredTime: file.expiredTime?.toString() || '',
                    previewUrl: file.previewUrl || '',
                },
            });
        };
        return {
            columns,
            dashboardFiles,
            dashboardTotalFiles,
            loading,
            fetchFiles,
            getFileIcon,
            getStatusColor,
            handleFileClick,
        };
    },
    methods: {
        handleAction(action, record) {
            switch (action) {
                case 'rename':
                    Message.info(`重命名文件: ${record.name}`);
                    break;
                case 'move':
                    Message.info(`移动文件: ${record.name}`);
                    break;
                case 'delete':
                    Message.warning(`删除文件: ${record.name}`);
                    break;
                default:
                    Message.info(`未知操作: ${action}`);
            }
        },
        handleItemClick(record) {
            if (record.type === 'folder') {
                this.$router.push({
                    name: 'SubFolder',
                    params: { id: record.id?.toString() || '' },
                });
            }
            else {
                console.log('Navigating to FileDetail with query:', {
                    code: record.code,
                    translatedFileCode: record.translatedFileCode,
                    name: record.name,
                    status: record.status,
                    duration: record.duration?.toString(),
                    createTime: record.createTime?.toString(),
                    expiredTime: record.expiredTime?.toString(),
                    previewUrl: record.previewUrl,
                });
                this.$router.push({
                    name: 'FileDetail',
                    query: {
                        code: record.code || '',
                        translatedFileCode: record.translatedFileCode || '',
                        name: record.name || '',
                        status: record.status || '',
                        duration: record.duration?.toString() || '',
                        createTime: record.createTime?.toString() || '',
                        expiredTime: record.expiredTime?.toString() || '',
                        previewUrl: record.previewUrl || '',
                    },
                });
            }
        },
    },
});
