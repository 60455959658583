import { ref, computed } from 'vue';
import axios from 'axios';
import SparkMD5 from 'spark-md5';
import { getMediaType } from '@/utils/utils';
import { watch } from 'vue';
import { baseURL } from './axios';
export var UploadState;
(function (UploadState) {
    UploadState[UploadState["Idle"] = 0] = "Idle";
    UploadState[UploadState["Uploading"] = 1] = "Uploading";
    UploadState[UploadState["Paused"] = 2] = "Paused";
    UploadState[UploadState["Completed"] = 3] = "Completed";
    UploadState[UploadState["Cancelled"] = 4] = "Cancelled";
    UploadState[UploadState["Failed"] = 5] = "Failed";
})(UploadState || (UploadState = {}));
export function useFileUploader() {
    const uploadState = ref(UploadState.Idle);
    const progress = ref(0);
    const currentTask = ref(null);
    const cancelTokenSource = ref(null);
    const startUpload = async (folderCode, fileName, file, url, accessToken, language, hasSpeaker) => {
        console.log('FileUploader.startUpload called with:', {
            folderCode,
            fileName,
            file,
            url,
            accessToken,
            language,
            hasSpeaker,
        });
        uploadState.value = UploadState.Uploading;
        currentTask.value = {
            id: Date.now().toString(),
            folderCode,
            fileName,
            file,
            url,
            language,
            hasSpeaker,
            accessToken,
            createdAt: Date.now(),
        };
        try {
            const fileCode = await uploadFile(currentTask.value);
            uploadState.value = UploadState.Completed;
            return fileCode;
        }
        catch (error) {
            if (error === 'cancelled') {
                uploadState.value = UploadState.Cancelled;
            }
            else {
                uploadState.value = UploadState.Failed;
            }
            throw error; // 确保错误被抛出
        }
        finally {
            currentTask.value = null;
            cancelTokenSource.value = null;
        }
    };
    const cancelUpload = () => {
        if (cancelTokenSource.value) {
            cancelTokenSource.value.abort();
        }
        uploadState.value = UploadState.Cancelled;
    };
    const uploadFile = async (task) => {
        const { file } = task;
        const fileSize = file.size;
        const FILE_SIZE_THRESHOLD = 10 * 1024 * 1024; // 10MB
        const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
        console.log(`Starting upload for file: ${task.fileName}, size: ${fileSize} bytes`);
        cancelTokenSource.value = new AbortController();
        console.log('Calculating file identifier...');
        const fileMd5 = await calculateFileIdentifier(file);
        console.log(`File identifier calculated: ${fileMd5}`);
        const headers = getHeaders(task, 0, fileSize, fileSize, fileMd5);
        console.log('Headers prepared:', headers);
        if (fileSize <= FILE_SIZE_THRESHOLD) {
            console.log('File size is small, using small file upload method');
            return uploadSmallFile(task, headers);
        }
        else {
            console.log('File size is large, using large file upload method');
            return uploadLargeFile(task, headers, CHUNK_SIZE);
        }
    };
    const uploadSmallFile = async (task, headers) => {
        console.log(`Uploading small file: ${task.fileName}`);
        const config = {
            headers,
            signal: cancelTokenSource.value?.signal,
            onUploadProgress: (progressEvent) => {
                if (progressEvent.total) {
                    progress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(`Upload progress: ${progress.value}%`);
                }
            },
        };
        try {
            console.log(`Sending PUT request to ${task.url}`);
            const response = await axios.put(baseURL + task.url, task.file, config);
            console.log('Upload response received:', response);
            return handleUploadCompletion(response);
        }
        catch (error) {
            if (axios.isCancel(error)) {
                console.log('Upload cancelled');
                throw new Error('cancelled');
            }
            if (error instanceof Error) {
                console.error('Upload error:', error.message);
                throw new Error(error.message);
            }
            console.error('Unknown upload error');
            throw new Error('An unknown error occurred during small file upload.');
        }
    };
    const uploadLargeFile = async (task, headers, chunkSize) => {
        const { file, url } = task;
        const fileSize = file.size;
        let uploadedLength = 0;
        const maxRetries = 5;
        const retryDelay = 2000;
        console.log(`Starting large file upload: ${task.fileName}, total size: ${fileSize} bytes`);
        while (uploadedLength < fileSize) {
            if (cancelTokenSource.value?.signal.aborted) {
                console.log('Upload cancelled');
                throw new Error('cancelled');
            }
            const endByte = Math.min(uploadedLength + chunkSize, fileSize);
            const chunk = file.slice(uploadedLength, endByte);
            console.log(`Uploading chunk: ${uploadedLength}-${endByte - 1}/${fileSize}`);
            let chunkUploaded = false;
            let retryCount = 0;
            while (!chunkUploaded && retryCount < maxRetries) {
                try {
                    const chunkHeaders = {
                        ...headers,
                        'Content-Range': `${uploadedLength}-${endByte - 1}/${fileSize}`,
                    };
                    const config = {
                        headers: chunkHeaders,
                        signal: cancelTokenSource.value?.signal,
                        onUploadProgress: (progressEvent) => {
                            if (progressEvent.total) {
                                const totalUploaded = uploadedLength + progressEvent.loaded;
                                progress.value = Math.round((totalUploaded * 100) / fileSize);
                                console.log(`Chunk upload progress: ${progress.value}%`);
                            }
                        },
                    };
                    console.log(`Sending chunk PUT request to ${url}`);
                    const response = await axios.put(baseURL + url, chunk, config);
                    console.log('Chunk upload response:', response.status);
                    if (response.status === 200 || response.status === 206) {
                        chunkUploaded = true;
                        uploadedLength = endByte;
                        console.log(`Chunk uploaded successfully, total uploaded: ${uploadedLength}/${fileSize}`);
                        if (uploadedLength >= fileSize) {
                            console.log('All chunks uploaded, finalizing...');
                            return handleUploadCompletion(response);
                        }
                    }
                    else {
                        throw new Error(`Unexpected response status: ${response.status}`);
                    }
                }
                catch (error) {
                    if (axios.isCancel(error)) {
                        console.log('Chunk upload cancelled');
                        throw new Error('cancelled');
                    }
                    if (error instanceof Error) {
                        console.error('Chunk upload error:', error.message);
                        retryCount++;
                        console.log(`Retrying chunk upload, attempt ${retryCount}/${maxRetries}`);
                        if (retryCount >= maxRetries) {
                            throw new Error('Failed to upload chunk after max retries');
                        }
                        await new Promise((resolve) => setTimeout(resolve, retryDelay));
                    }
                }
            }
            if (!chunkUploaded) {
                console.error('Failed to upload chunk after max retries');
                throw new Error('Failed to upload chunk after max retries');
            }
        }
        console.error('Unexpected end of upload');
        throw new Error('Unexpected end of upload');
    };
    const getHeaders = (task, start, end, total, fileMd5) => {
        const headers = {
            fileName: encodeURIComponent(task.fileName),
            'Content-Type': getMediaType(task.fileName.split('.').pop() || '') ||
                'application/octet-stream',
            Authorization: `Bearer ${task.accessToken}`,
            language: task.language,
            hasSpeaker: task.hasSpeaker.toString(),
            fileMd5,
        };
        if (task.folderCode) {
            headers['folderCode'] = task.folderCode;
        }
        return headers;
    };
    const handleUploadCompletion = (response) => {
        if (response.data.code !== 0) {
            throw new Error(response.data.msg || 'Unknown error');
        }
        const fileItem = response.data.data;
        if (!fileItem || !fileItem.code) {
            throw new Error('Upload failed, no file code');
        }
        return fileItem.code;
    };
    const calculateFileIdentifier = async (file) => {
        return new Promise((resolve, reject) => {
            const chunkSize = 2097152; // Read in chunks of 2MB
            const spark = new SparkMD5.ArrayBuffer();
            const fileReader = new FileReader();
            let currentChunk = 0;
            const loadNext = () => {
                const start = currentChunk * chunkSize;
                const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
                fileReader.readAsArrayBuffer(file.slice(start, end));
            };
            fileReader.onload = (e) => {
                spark.append(e.target?.result);
                currentChunk++;
                if (currentChunk * chunkSize < file.size) {
                    loadNext();
                }
                else {
                    resolve(spark.end());
                }
            };
            fileReader.onerror = () => {
                reject('Failed to calculate file identifier');
            };
            loadNext();
        });
    };
    return {
        uploadState: computed(() => uploadState.value),
        progress: computed(() => progress.value),
        startUpload,
        cancelUpload,
        addUploadStateListener: (listener) => {
            // 实现添加监听器的逻辑
            watch(uploadState, (newState) => {
                listener(newState);
            });
        },
        addProgressListener: (listener) => {
            // 实现添加进度监听器的逻辑
            watch(progress, (newProgress) => {
                listener(newProgress);
            });
        },
    };
}
// 修改导出方式
const fileUploader = useFileUploader();
export default {
    uploadState: fileUploader.uploadState,
    progress: fileUploader.progress,
    startUpload: fileUploader.startUpload,
    cancelUpload: fileUploader.cancelUpload,
    addUploadStateListener: fileUploader.addUploadStateListener,
    addProgressListener: fileUploader.addProgressListener,
};
