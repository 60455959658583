import { defineComponent, ref, onMounted, computed, watch } from 'vue';
import { IconMore, IconLeft } from '@arco-design/web-vue/es/icon';
import { Message } from '@arco-design/web-vue';
import { useRouter, useRoute } from 'vue-router';
import folderIcon from '@/assets/file_type_folder.png';
import videoIcon from '@/assets/file_type_video.png';
import audioIcon from '@/assets/file_type_audio.png';
import meetingIcon from '@/assets/file_type_meeting.png';
import { useStore } from 'vuex';
export default defineComponent({
    name: 'SubFolder',
    components: { IconMore, IconLeft },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const folderId = route.params.id;
        const folderName = ref('我的文件夹2');
        const files = computed(() => store.getters['fileStore/getFiles'](`subFolder-${folderId}`));
        const totalFiles = computed(() => store.getters['fileStore/getTotalFiles'](`subFolder-${folderId}`));
        const currentPage = computed(() => store.getters['fileStore/getCurrentPage'](`subFolder-${folderId}`));
        const pageSize = computed(() => store.getters['fileStore/getPageSize'](`subFolder-${folderId}`));
        const columns = [
            { title: '文件名称', dataIndex: 'name', slotName: 'name' },
            { title: '创建时间', dataIndex: 'createdAt' },
            { title: '保留时长', dataIndex: 'retention' },
            { title: '操作', dataIndex: 'actions', slotName: 'actions', width: 80 },
        ];
        const getFileIcon = (type) => {
            switch (type) {
                case 'folder':
                    return folderIcon;
                case 'video':
                    return videoIcon;
                case 'audio':
                    return audioIcon;
                case 'meeting':
                    return meetingIcon;
                default:
                    return folderIcon;
            }
        };
        const getStatusColor = (status) => {
            switch (status) {
                case '下载中':
                    return 'orange';
                case '转录中':
                    return 'blue';
                case '转录完成':
                    return 'green';
                case '转录失败':
                    return 'red';
                default:
                    return 'default';
            }
        };
        const handleAction = (action, record) => {
            switch (action) {
                case 'rename':
                    Message.info(`重命名文件: ${record.name}`);
                    break;
                case 'move':
                    Message.info(`移动文件: ${record.name}`);
                    break;
                case 'delete':
                    Message.warning(`删除文件: ${record.name}`);
                    break;
                default:
                    Message.info(`未知操作: ${action}`);
            }
        };
        const handlePageChange = (page) => {
            store.dispatch('fileStore/changePage', {
                key: `subFolder-${folderId}`,
                page,
            });
        };
        const goBack = () => {
            router.back();
        };
        const fetchFiles = async () => {
            await store.dispatch('fileStore/fetchFiles', {
                key: `subFolder-${folderId}`,
            });
        };
        onMounted(() => {
            fetchFiles();
        });
        watch(() => route.params.id, () => {
            fetchFiles();
        });
        return {
            folderName,
            files,
            columns,
            totalFiles,
            currentPage,
            pageSize,
            getFileIcon,
            getStatusColor,
            handleAction,
            handlePageChange,
            goBack,
        };
    },
});
