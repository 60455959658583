import axios from 'axios';
import { Message } from '@arco-design/web-vue';
import router from '@/router';
export const baseURL = process.env.VUE_APP_API_BASE_URL || 'https://test-api.xtranscript.com';
const axiosInstance = axios.create({
    baseURL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});
axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('auth_token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    console.log('Request:', {
        method: config.method,
        url: config.url,
        data: config.data,
        params: config.params,
        headers: config.headers,
    });
    return config;
}, (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
});
axiosInstance.interceptors.response.use((response) => {
    console.log('base response:', {
        status: response.status,
        statusText: response.statusText,
        data: response.data,
        headers: response.headers,
    });
    const res = response.data;
    if (res.code !== 0) {
        switch (res.code) {
            case 401:
                Message.error('登录已过期，请重新登录');
                router.push('/login');
                break;
            case 403:
                Message.error('没有权限执行此操作');
                break;
            case 500:
                Message.error('服务器错误，请稍后重试');
                break;
            default:
                Message.error(res.msg || '请求失败，请稍后重试');
        }
        return Promise.reject(new Error(res.msg || 'Error'));
    }
    return res;
}, (error) => {
    console.error('Response error:', {
        message: error.message,
        response: error.response
            ? {
                status: error.response.status,
                statusText: error.response.statusText,
                data: error.response.data,
                headers: error.response.headers,
            }
            : 'No response',
        request: error.request
            ? 'Request made but no response received'
            : 'Error setting up request',
    });
    if (error.response) {
        switch (error.response.status) {
            case 401:
                Message.error('登录已过期，请重新登录');
                router.push('/login');
                break;
            case 403:
                Message.error('没有权限执行此操作');
                break;
            case 500:
                Message.error('服务器错误，请稍后重试');
                break;
            default:
                Message.error(error.response.data?.msg || '请求失败，请稍后重试');
        }
    }
    else if (error.request) {
        Message.error('网络错误，请检查您的网络连接');
    }
    else {
        Message.error('发生未知错误，请稍后重试');
    }
    return Promise.reject(error);
});
export default axiosInstance;
