import { createRouter, createWebHistory } from 'vue-router';
import UserAgreement from '@/pages/UserAgreement.vue';
import HomePage from '@/pages/HomePage.vue';
import PricePage from '@/pages/PricePage.vue';
import DownloadPage from '@/pages/DownloadPage.vue';
import PrivacyPolicy from '@/pages/PrivacyPolicy.vue';
import DeleteAccount from '@/pages/DeleteAccount.vue';
import MainPage from '@/pages/MainPage.vue';
import Dashboard from '@/pages_main/Dashboard.vue';
import FileManager from '@/pages_main/FileManager.vue';
import LoginPage from '@/pages/LoginPage.vue';
import RegisterPage from '@/pages/RegisterPage.vue';
import ForgetPasswordPage from '@/pages/ForgetPasswordPage.vue';
import Feedback from '@/pages_main/Feedback.vue';
import NotificationEmailManage from '@/pages_main/NotificationEmailManage.vue';
import AccountInfo from '@/pages_main/AccountInfo.vue';
import FileDetail from '@/pages_main/FileDetail.vue';
import SubFolder from '@/pages_main/SubFolder.vue';
import LegalInformation from '@/pages/legalInformation.vue';
const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage,
    },
    {
        path: '/pricing',
        name: 'Price',
        component: PricePage,
    },
    {
        path: '/download',
        name: 'Download',
        component: DownloadPage,
    },
    {
        path: '/user-agreement',
        name: 'UserAgreement',
        component: UserAgreement,
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
    },
    {
        path: '/delete-account',
        name: 'DeleteAccount',
        component: DeleteAccount,
    },
    {
        path: '/legal-information',
        name: 'LegalInformation',
        component: LegalInformation,
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
    },
    {
        path: '/register',
        name: 'RegisterPage',
        component: RegisterPage,
    },
    {
        path: '/forget-password',
        name: 'ForgetPasswordPage',
        component: ForgetPasswordPage,
    },
    {
        path: '/main',
        name: 'Main',
        component: MainPage,
        children: [
            {
                path: '/main/dashboard',
                name: 'Dashboard',
                component: Dashboard,
            },
            {
                path: '/main/file-manager',
                name: 'FileManager',
                component: FileManager,
            },
            {
                path: '/main/file-detail',
                name: 'FileDetail',
                component: FileDetail,
            },
            {
                path: '/main/sub-folder',
                name: 'SubFolder',
                component: SubFolder,
            },
            {
                path: '/main/feedback',
                name: 'Feedback',
                component: Feedback,
            },
            {
                path: '/main/notification-email-manage',
                name: 'NotificationEmailManage',
                component: NotificationEmailManage,
            },
            {
                path: '/main/account-info',
                name: 'AccountInfo',
                component: AccountInfo,
            },
            // 可以添加一个重定向，确保进入 /main 时默认显示仪表盘
            { path: '', redirect: '/main/dashboard' },
        ],
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
export default router;
